<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">. . .</li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/pengukuran-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Pengukuran Paket</span></router-link></li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/pengiriman-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Pengiriman Paket</span></router-link></li>                        
            <li class="breadcrumb-item active" aria-current="page">Pembayaran</li>						
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>            
            <div class="col-xl-8 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <form class="pt-3">
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Jenis Layanan</label></b-col>
                          <b-col lg="12"><h6>{{ data.features_name }}</h6></b-col>                          
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Tanggal</label></b-col>
                          <b-col lg="12"><h6>{{ data.updated_at }}</h6></b-col>                          
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Metode Pembayaran</label></b-col>
                          <b-col lg="12"><h6>{{ data.payment_methods_name }}</h6></b-col>                          
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Total Biaya</label></b-col>
                          <b-col lg="12"><h6>Rp. {{ data.total_price }}</h6></b-col>                          
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="6"><label>Nama Bank</label></b-col>
                        </b-row>                    
                        <input type="text" v-model="form.bank_name" class="form-control form-control-lg" placeholder="">
                      </div>
                    </div>
                    <div class="col-lg-12">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="6"><label>Nomor Rekening</label></b-col>
                        </b-row>                    
                        <input type="number" v-model="form.number_account" class="form-control form-control-lg" placeholder="">
                      </div>
                    </div>  
                    <div class="col-lg-12">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="6"><label>Nama Pemilik Rekening</label></b-col>
                        </b-row>                    
                        <input type="text" v-model="form.account_owner" class="form-control form-control-lg" placeholder="">
                      </div>
                    </div>

                    <div class="col-lg-12">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="6"><label>Silahkan upload bukti pembayaran kamu</label></b-col>
                        </b-row>
                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                      </div>
                    </div>                    
                  </div>                  
                  <div class="mt-3">
                    <template v-if="alert.status != 0">
                      <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                    </template>
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Bayar</b-button>                    
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      unitDimension : "cm",
      cubicM: "\u33A5",
      errorMassage:0,
      formUpload: {
      },      
      form:{
        route: '',
        route_title: 'bukti_pembayaran',        
        bank_name : '',
        number_account : '',
        account_owner : '',        
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataOption1: [],
      optSelect: '',
      optionsRadio: [],
      selected: 'first', 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
		handleFileUpload(){			
			this.formUpload.image = this.$refs.file.files[0];			
		},    
    getPaymentMethod: function () {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/payment-method?search=-&page=1&limit=999`,packageGlobals.configAxios)      
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
			})							
    },
    getData: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/${uuid}`,packageGlobals.configAxios)
			.then((response) => {        
        this.form.route = '/operasional/antar-barang/bukti-pembayaran/'+this.params.uuid        
        this.data = response.data.result
        console.log(this.data);
			})      
    },    
    // onNext(evt) {
    onNext() {      

      this.created_by = localStorage.getItem("uuid")
      let formData = new FormData();
      formData.append('file', this.formUpload.image);          
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      // this.configAxios.headers["Content-Type"] = "multipart/form-data";
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/upload/${this.params.uuid}/${localStorage.getItem("uuid")}`,formData)

      this.form.uuid_payment_methods = this.optSelect['id']
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/selesai/${this.params.uuid}`)
      })
    }
  },
  mounted() {
		this.getPaymentMethod()
    this.getData(this.params.uuid)        
    // this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}

.input-group-text{
  font-weight: bold;
}
</style>